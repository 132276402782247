import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VerifyDrivingLicenceForm from "./component";
import { getLicenceUploadStatusByOrderId, saveDrivingLicenceDetails, setLicenceUploadScreenType } from "../../checkout-info/actions";

const mapStateToProps = ({
    checkout: {
        signContractData
    },
    carDetails: {
        content
    }
}) => ({
    signContractData,
    content
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getLicenceUploadStatusByOrderIdConnect: getLicenceUploadStatusByOrderId,
    saveDrivingLicenceDetailsConnect: saveDrivingLicenceDetails,
    setLicenceUploadScreenTypeConnect: setLicenceUploadScreenType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDrivingLicenceForm);
