import React from "react";
import styles from "./styles.css";
import VerifyIcon from "./images/verify-id-icon.svg";
import { useHistory } from "react-router";
// import { getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import GreenCheck from "./images/green-check.svg";
import Pending from "./images/pending.svg";
import ErrorIcon from "./images/error.svg";
// import { tasksRoutes } from "../../../constants/au.mobile/tasks-routes";
import PropTypes from "prop-types";
import {
    // VERIFICATION_STATUS,
    VERIFY_LICENCE_STATES } from "../../../constants/checkout-constants";
//import TaskCardShimmer from "../task-card-shimmer";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { VERIFY_LICENCE_SCREEN_TYPES } from "../../../constants/app-constants";
import { postPaymentRoutes } from "../../../constants/au.mobile/post-payment-routes";
import { getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import ArrowBack from "./images/arrow-back.svg";
import ArrowIcon from "../../shared/arrow";

// const { CREATED } = VERIFICATION_STATUS;
const { VERIFICATION_PENDING, ID_VERIFIED, FAILED, UPLOAD_PENDING } = VERIFY_LICENCE_STATES;
// const renderSubText = {
//     [PENDING]: "A member of our team will be in contact to manually verify your ID",
//     [VERIFIED]: "Your drivers licence has been verified",
//     [FAILED]: "A member of our team will be in contact to manually verify your ID"
// };
const renderSubText = {
    [VERIFICATION_PENDING]: "A member of our team will be in touch to verify your ID",
    [ID_VERIFIED]: "Your drivers licence has been verified",
    [FAILED]: "A member of our team will be in touch to verify your ID",
    [UPLOAD_PENDING]: "We need to verify your licence before we can deliver your car"
};

const GreenIdCard = ({
    verificationData,
    //verifyIdCompleted,
    // verificationDataError,
    showVariant = "VARIANT_A",
    setLicenceUploadScreenTypeConnect = () => {},
    appointmentId,
    drivingLicenceStatus,
    hideStatus
}) => {
    const history = useHistory();
    const {
        // status,
        // verificationToken,
        orderId } = verificationData || {};
    const handleVerifyIdClick = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.GREEN_ID_BUTTON_CLICK, {eventLabel: `${orderId}`});
        setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_FORM);
        const {relativeURL} = getMyBookingCarDetailsURL(appointmentId, postPaymentRoutes.driverLicence.route,
            null,
            EXPERIMENT_TYPE.VARIANT_B);
        history.push(relativeURL);
    };

    const renderVerificationIdStatus = () => {
        if (hideStatus && drivingLicenceStatus !== UPLOAD_PENDING) {
            return null;
        }
        switch (drivingLicenceStatus) {
        case UPLOAD_PENDING:
            return (
                <React.Fragment>
                    {showVariant === EXPERIMENT_TYPE.VARIANT_A && (
                        <span styleName={"styles.cardCta"} onClick={() => handleVerifyIdClick(true)}>
                            VERIFY ID
                        </span>
                    )}
                    {showVariant === EXPERIMENT_TYPE.VARIANT_B && (
                        <div styleName="styles.buttonWrapper">
                            <button onClick={handleVerifyIdClick}>
                                Verify Driver’s licence
                                <img src={ArrowBack} />
                            </button>
                        </div>
                    )}
                </React.Fragment>
            );
        case VERIFICATION_PENDING:
            return (
                <React.Fragment>
                    <span styleName={"styles.cardCtaPending"}>
                        Verification pending
                        <img src={Pending} alt="Pending" />
                    </span>
                    <p styleName={"styles.verifyAction"} onClick={handleVerifyIdClick}>
                        View uploaded documents
                        <ArrowIcon blue={true} />
                    </p>
                </React.Fragment>
            );
        case FAILED:
            return (
                <React.Fragment>
                    <span styleName={"styles.cardCtaFailed"}>
                        Failed
                        <img src={ErrorIcon} alt="Error" />
                    </span>
                    <p styleName={"styles.verifyAction"} onClick={handleVerifyIdClick}>
                        View uploaded documents
                        <ArrowIcon blue={true} />
                    </p>
                </React.Fragment>
            );
        case ID_VERIFIED:
            return (
                <React.Fragment>
                    <span styleName={"styles.cardCta styles.approvedGreen"}>
                        ID verified
                        <img src={GreenCheck} alt="Approved" />
                    </span>
                    <p styleName={"styles.verifyAction"} onClick={handleVerifyIdClick}>
                        View uploaded documents
                        <ArrowIcon blue={true} />
                    </p>
                </React.Fragment>
            );
        default:
            return (
                <React.Fragment>
                    {showVariant === EXPERIMENT_TYPE.VARIANT_A && <span styleName={"styles.cardCta"} onClick={handleVerifyIdClick}>VERIFY ID</span>}
                    {showVariant === EXPERIMENT_TYPE.VARIANT_B &&
                    <div styleName="styles.buttonWrapper">
                        <button onClick={handleVerifyIdClick}>Verify Driver’s licence<img src={ArrowBack} /></button>
                    </div>}
                </React.Fragment>

            );
        }
    };

    // if (!verifyIdCompleted) {
    //     return <TaskCardShimmer />;
    // }

    return (
        <React.Fragment>
            {showVariant === EXPERIMENT_TYPE.VARIANT_A && (
                <div styleName={"styles.taskCard "}>
                    <h3>Upload Drivers Licence</h3>
                    <p styleName={"styles.contractText"}>{renderSubText[drivingLicenceStatus]}</p>
                    {renderVerificationIdStatus()}
                    <img styleName={"styles.cardImage"} src={VerifyIcon} alt="verify id" />
                </div>
            )}
            {showVariant === EXPERIMENT_TYPE.VARIANT_B && (
                <div styleName={"styles.taskCardB styles.taskCardB"}>
                    {/* <div styleName="styles.headerWrapper">
                    {status === CREATED && <p>Due now</p>}
                </div> */}
                    {/*{renderSubText[PENDING] && <p styleName={"styles.contractText"}>{renderSubText[PENDING]}</p>}*/}
                    {renderVerificationIdStatus()}
                </div>
            )}
        </React.Fragment>
    );
};

GreenIdCard.propTypes = {
    verificationData: PropTypes.object,
    verifyIdCompleted: PropTypes.bool,
    verificationDataError: PropTypes.object,
    showVariant: PropTypes.string,
    setLicenceUploadScreenTypeConnect: PropTypes.func,
    appointmentId: PropTypes.func,
    drivingLicenceStatus: PropTypes.string,
    hideStatus: PropTypes.bool
};

export default GreenIdCard;

