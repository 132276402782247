/* eslint-disable filenames/match-regex */
/* eslint-disable complexity */
import * as Yup from "yup";
import { LICENCE_STATE_OPTIONS } from "../../../constants/checkout-constants";
import { NUMBER } from "../../../constants/app-constants";

export const LICENCE_TYPE_OPTIONS = [
    { label: "Full", value: "FULL" },
    { label: "Provisional", value: "PROVISIONAL" },
    { label: "Heavy Vehicle", value: "HEAVY_VEHICLE" },
    { label: "Learner", value: "LEARNER" },
    { label: "None", value: "NONE" }
];

const verifyDrivingLicenceConfig = ({ components = {}, formData }) => {
    let { drivingLicenseDetails = {} } = formData || {};

    drivingLicenseDetails = drivingLicenseDetails || {};
    formData = {
        ...formData,
        drivingLicenseDetails: {
            media: !drivingLicenseDetails.media ? [null, null] : drivingLicenseDetails.media,
            ...drivingLicenseDetails
        }
    };

    const formFieldDisabled = formData.disabled || false;

    return {
        formId: "v1_upload",
        totalStages: 1,
        formTitle: "Upload Documents",
        introTexts: ["Your driving license"],
        stages: [
            {
                name: "Driving license",
                stageNo: 1,
                totalPages: 1,
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            return !!drivingLicenseDetails.licenceType;
                        },
                        questions: [
                            {
                                questionLabel: "Licence type",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceType",
                                    fieldType: components.select,
                                    shortTitle: "Licence type",
                                    title: null,
                                    isVisible: true,
                                    options: LICENCE_TYPE_OPTIONS,
                                    placeholder: "Type of license you hold",
                                    isRequired: true,
                                    disabled: formFieldDisabled,
                                    props: {
                                        data: LICENCE_TYPE_OPTIONS
                                    }
                                }]
                            },
                            {
                                questionLabel: "Drivers licence number",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceNumber",
                                    fieldType: components.inputText,
                                    shortTitle: "Drivers licence number",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "DL number as mentioned on your license",
                                    isRequired: true,
                                    disabled: formFieldDisabled,
                                    props: {}
                                }]
                            },
                            {
                                questionLabel: "Licence state",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceState",
                                    fieldType: components.select,
                                    shortTitle: "Licence state",
                                    title: null,
                                    isVisible: true,
                                    options: LICENCE_STATE_OPTIONS,
                                    placeholder: "Select the state you hold your license in",
                                    disabled: formFieldDisabled,
                                    isRequired: true,
                                    props: {
                                        data: LICENCE_STATE_OPTIONS
                                    }
                                }]
                            },
                            {
                                questionLabel: "Driving license front",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.media[1]",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Driving license front",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    disabled: formFieldDisabled,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        type: "front",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: true
                                    }
                                }]
                            },
                            {
                                questionLabel: "Driving license back",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.media[0]",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Driving license back",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    disabled: formFieldDisabled,
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        type: "back",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: true
                                    }
                                }]
                            }

                        ],
                        formValidator: Yup.object().shape({
                            drivingLicenseDetails: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                media: Yup.array()
                                    .of(
                                        Yup.object().shape({
                                            fileName: Yup.string().required("Please upload a valid document.")
                                        }).nullable().required("Please upload a valid document.")
                                    )
                                    .min(NUMBER.TWO, "Please upload a valid document.")
                                    .required("Please upload a valid document.").nullable(),
                                licenceType: Yup.string().required("Please select a valid option ").nullable(),
                                licenceNumber: Yup.string().required("Please enter a valid text").nullable(),
                                licenceState: Yup.string().required("Please select a valid option ").nullable()
                            })
                        }),
                        initialValues: {
                            drivingLicenseDetails: formData.drivingLicenseDetails
                        }
                    }

                ]
            }
        ]
    };
};

export {
    verifyDrivingLicenceConfig
};
