import React from "react";
import { useSelector } from "react-redux";

const usePreBiAssistance = () => {
    const {
        supportWidget = {},
        user: {
            isFirstUserSession = false
        } = {}
    } = useSelector((state) => state || {});
    const {isModalContentSeen = false, launchNudge = false, showNudge = false} = supportWidget || {};
    const renderSupportNudge = !!(!isModalContentSeen && launchNudge && showNudge);
    return {renderSupportNudge, isFirstUserSession};
};

export default usePreBiAssistance;
