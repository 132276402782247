import React, {useEffect, useState} from "react";
import { postPaymentRoutes } from "../../../../constants/au.mobile/post-payment-routes";
import { EXPERIMENT_TYPE } from "../../../../constants/optimize-constants";
import { getMyBookingCarDetailsURL } from "../../../../utils/helpers/get-detail-page-url";
import GreenIdCheck from "../../green-id-card";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { SIGN_CONTRACT_STATUS, VERIFY_LICENCE_STATES } from "../../../../constants/checkout-constants";
import CheckIcon from "./images/check-icon.svg";
// import ReservingYourCard from "../../reserving-your-car-card/component";
// import TaskCardShimmer from "../../task-card-shimmer/component";
import VerifyDrivingLicenceForm from "../verify-user-licence-form";

import StepsComponentRevamp from "../../../shared/steps-component-revamp";
import { NUMBER, VERIFY_LICENCE_SCREEN_TYPES } from "../../../../constants/app-constants";
import ConfirmBookingReserved from "../../../au.desktop/confirm-booking-reserved/component";
import { CONGRATULATION_SOURCES } from "../../../au.desktop/confirm-booking-reserved/constants";
import { tasksRoutes } from "../../../../constants/au.mobile/tasks-routes";
import LoaderScreen from "../../loader-screen/component";
import { getAllSettledPromises } from "../../../../utils/helpers/get-all-settled-promises";
import useCustomHistoryOperations from "../../../../hooks/use-custom-history-operation";

const STEPS = [
    "You’ll be required to upload a valid Australian driving license for verification.",
    "We will cross-check your details with official Australian ID databases.",
    "Additional identity documents may be requested to ensure full verification.",
    "Only the driving license of the intended car owner should be uploaded for this process."
];

const VerifyUserLicence = ({
    order,
    signContractData = [],
    getPaymentSummaryConnect = () => {},
    setLicenceUploadScreenTypeConnect = () => {},
    drivingLicenceData = {},
    getLicenceUploadStatusByOrderIdConnect
    // reconcilePending = null
}) => {
    // const [paymentSummaryLoading, setPaymentSummaryLoading] = React.useState(false);
    const {screenType = VERIFY_LICENCE_SCREEN_TYPES.INTRO} = drivingLicenceData || {};
    const { customReplace } = useCustomHistoryOperations();
    const {documentStatus} = Array.isArray(signContractData) && signContractData[0] || {};
    const [dataLoading, setDataLoading] = useState(false);

    const {orderId = "", drivingLicenceStatus} = order;

    const handleSkip = async () => {
        const {relativeURL} = getMyBookingCarDetailsURL(order.appointmentId, postPaymentRoutes.signContract.route, null, EXPERIMENT_TYPE.VARIANT_B);
        await customReplace(relativeURL);
    };

    const fetchOrderAndDeliveryDetails = async () => {
        setDataLoading(true);
        let asyncOperations = { paymentSummary: getPaymentSummaryConnect(orderId) };

        if (drivingLicenceStatus !== VERIFY_LICENCE_STATES.UPLOAD_PENDING) {
            asyncOperations = {
                ...asyncOperations,
                dlData: getLicenceUploadStatusByOrderIdConnect()
            };
        }

        const { dlData } = await getAllSettledPromises(asyncOperations);

        if (dlData && (dlData?.value?.status !== VERIFY_LICENCE_STATES.UPLOAD_PENDING)) {
            setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_FORM);
        } else {
            setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.INTRO);
        }

        setDataLoading(false);
    };

    useEffect(() => {
        if (orderId) {
            fetchOrderAndDeliveryDetails();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    useEffect(() => {
        if (screenType === VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_CONFIRMATION) {
            window.setTimeout(() => {
                if (documentStatus !== SIGN_CONTRACT_STATUS.SIGNED) {
                    const {relativeURL} = getMyBookingCarDetailsURL(order.appointmentId, postPaymentRoutes.signContract.route, null, EXPERIMENT_TYPE.VARIANT_B);
                    customReplace(relativeURL);
                } else {
                    const {relativeURL} = getMyBookingCarDetailsURL(order.appointmentId, tasksRoutes.viewTask.route);
                    customReplace(relativeURL);
                }

            }, NUMBER.THREE_THOUSAND);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenType, drivingLicenceStatus]);
    const renderScreen = () => {
        switch (screenType) {
        case VERIFY_LICENCE_SCREEN_TYPES.INTRO:
            return (<React.Fragment><p styleName={"styles.header"}>Verify your ID</p>
                <div styleName="styles.reserveCarInfo">
                    <img src={CheckIcon} alt="info"/>
                    <div styleName="styles.subheader">
                        <p styleName={"styles.congratulation"}>Congratulations on reserving your car!</p>
                        <p styleName={"styles.congratulationSubHeader"}>Order isn’t complete until you verify your ID and sign the contract of sale</p>
                    </div>
                </div>
                <div styleName="styles.stepsWrapper">
                    <StepsComponentRevamp title="Next Steps" steps={STEPS} />
                </div>
                <GreenIdCheck showVariant={EXPERIMENT_TYPE.VARIANT_B} hideStatus={true}/>
                {documentStatus !== SIGN_CONTRACT_STATUS.SIGNED && <div onClick={handleSkip} styleName={"styles.skipDl"}><a styleName={"styles.skipDLText"}>Skip, I do not have an Australian DL</a></div>}
            </React.Fragment>);
        case VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_FORM:
            return <VerifyDrivingLicenceForm drivingLicenceData={drivingLicenceData}/>;
        case VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_CONFIRMATION:
            return (<ConfirmBookingReserved
                successText="Documents Uploaded successfully"
                source={CONGRATULATION_SOURCES.DRIVING_LICENCE_SUBMITTED}
                isCashBooking={true}
                showFirstName={false}
                showCars24Logo={false}

            />);
        default:
            return null;
        }
    };
    return (
        <div styleName={screenType === VERIFY_LICENCE_SCREEN_TYPES.INTRO ? "styles.wrapper" : ""}>
            {dataLoading ? (
                <LoaderScreen title={"Please wait"} />
            ) :
                renderScreen()
            }
        </div>

    );
};
VerifyUserLicence.propTypes = {
    order: PropTypes.object,
    signContractData: PropTypes.array,
    getPaymentSummaryConnect: PropTypes.func,
    reconcilePending: PropTypes.bool,
    drivingLicenceData: PropTypes.object,
    setLicenceUploadScreenTypeConnect: PropTypes.func,
    getLicenceUploadStatusByOrderIdConnect: PropTypes.func
};
export default VerifyUserLicence;
