import React, {useState} from "react";
import PropTypes from "prop-types";
// import FormLayoutV1 from "../form-layout-v1";
import styles from "./styles.css";
import DynamicForm from "../../dynamic-form";
import FormLayoutV1 from "../../form-layout-v1";
import InputText from "../../../shared/input-text";
import Search from "../../../shared/search";
import { verifyDrivingLicenceConfig } from "../../../../config/au.mobile/post-bc-configs/verify-licence-form-config";
import FormFooter from "../../form-footer";
import { VERIFY_LICENCE_SCREEN_TYPES } from "../../../../constants/app-constants";
import VerifyLicenceUploadFileContainer from "../../verify-licence-upload-file-container";
import { SIGN_CONTRACT_STATUS } from "../../../../constants/checkout-constants";
import { useHistory } from "react-router-dom";
import SuccessIcon from "../../../shared/images/success-tick.svg";
import { tasksRoutes } from "../../../../constants/au.mobile/tasks-routes";
import { getMyBookingCarDetailsURL } from "../../../../utils/helpers/get-detail-page-url";
import { EXPERIMENT_TYPE } from "../../../../constants/optimize-constants";
import { LICENCE_FILE_UPLOADED_STATES, VERIFY_LICENCE_STATES } from "../../../../constants/licence-constant";

const getFormConfig = (formConfig, formData) => {
    const config =
        formConfig &&
        formConfig({
            components: {
                inputText: InputText,
                uploadFile: VerifyLicenceUploadFileContainer,
                select: Search
            },
            formData
        });
    return config;
};

const VerifyDrivingLicenceForm = ({
    // getLicenceUploadStatusByOrderIdConnect,
    saveDrivingLicenceDetailsConnect,
    setLicenceUploadScreenTypeConnect,
    drivingLicenceData,
    signContractData,
    content
}) => {
    const history = useHistory();

    const [ dlDetailsLoading, setDlDetailsLoading ] = useState(false);

    const { documentStatus } = (Array.isArray(signContractData) && signContractData[0]) || {};

    const formData = {};
    const {
        frontUrl,
        backUrl,
        number: dlNumber,
        type: dlType,
        state: dlState,
        status: dlUploadStatus,
        frontImageMeta,
        backImageMeta
    } = drivingLicenceData || {};

    const currentData = {
        licenceNumber: dlNumber,
        licenceState: dlState,
        licenceType: dlType,
        media: []
    };

    if (LICENCE_FILE_UPLOADED_STATES.includes(dlUploadStatus)) {
        currentData.media.push(
            {
                ...backImageMeta,
                size: backImageMeta?.fileSize,
                filePath: backUrl,
                isDefaultFileSize: !!backImageMeta?.fileSize
            },
            {
                ...frontImageMeta,
                size: frontImageMeta?.fileSize,
                filePath: frontUrl,
                isDefaultFileSize: !!frontImageMeta?.fileSize
            }
        );
    }

    const config = getFormConfig(verifyDrivingLicenceConfig, {
        drivingLicenseDetails: currentData,
        disabled: dlUploadStatus === VERIFY_LICENCE_STATES.ID_VERIFIED
    });

    const progressSteps = config.stages;
    const handleNext = async ({ values }) => {
        if (dlUploadStatus === VERIFY_LICENCE_STATES.ID_VERIFIED) {
            const nextStepRoute =
                documentStatus === SIGN_CONTRACT_STATUS.SIGNED
                    ? tasksRoutes.viewTask.route
                    : tasksRoutes.signContract.route;
            const { relativeURL: viewTaskUrl } = getMyBookingCarDetailsURL(
                content.appointmentId,
                nextStepRoute,
                null,
                EXPERIMENT_TYPE.VARIANT_B
            );

            history.push(viewTaskUrl);
        } else {
            setDlDetailsLoading(true);
            const { drivingLicenseDetails } = values;
            const { licenceState: state, licenceType: type, licenceNumber: number } = drivingLicenseDetails;
            const payload = {
                state,
                type,
                number
            };
            await saveDrivingLicenceDetailsConnect(payload);
            setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_CONFIRMATION);
            setDlDetailsLoading(false);
        }
    };

    const handleBack = () => {
        if (LICENCE_FILE_UPLOADED_STATES.includes(dlUploadStatus)) {
            history.goBack();
        } else setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.INTRO);
    };

    const TopComponent = () => {
        if (dlUploadStatus === VERIFY_LICENCE_STATES.ID_VERIFIED) {
            return (
                <div styleName={"styles.verifiedMessageStatus"}>
                    <img src={SuccessIcon} alt="success-icon" />
                    <p styleName={"styles.message"}>Your ID has been verified successfully</p>
                </div>
            );
        } else return null;
    };

    return (
        <div styleName={"styles.outer"}>
            <TopComponent/>
            <p styleName={"styles.heading"}>Driver's licence</p>
            <p styleName={"styles.subHeading"}>Only select yes if your business has an active ABN </p>
            <FormLayoutV1
                outerContainer={"container"}
                formConfig={config}
                handleOnNext={handleNext}
                handleOnBack={handleBack}
                formData={formData}
                isNextLoading={dlDetailsLoading}
                steps={progressSteps}
                middleComponent={DynamicForm}
                middleComponentProps={{
                    middleComponentStyles: styles.middleComponentStyles
                }}
            >
                {({ onClickBack, onClickNext, isNextLoading, currentFormData }) => {
                    return (
                        <FormFooter
                            onClickBack={onClickBack}
                            onClickNext={onClickNext}
                            isNextLoading={isNextLoading}
                            currentFormData={currentFormData}
                        />
                    );
                }}
            </FormLayoutV1>
        </div>
    );
};

VerifyDrivingLicenceForm.propTypes = {
    setScreenType: PropTypes.func,
    getLicenceUploadStatusByOrderIdConnect: PropTypes.func,
    saveDrivingLicenceDetailsConnect: PropTypes.func,
    setLicenceUploadScreenTypeConnect: PropTypes.func,
    drivingLicenceData: PropTypes.object,
    signContractData: PropTypes.object,
    content: PropTypes.object
};

export default VerifyDrivingLicenceForm;
